<template>
    <BT-Blade-Item
        bladeName="public-supplier"
        cardWidth="100%"
        hideBladeHeader
        :loadingMsg="loadingMsg"
        navigation="public-suppliers"
        @fetched="itemFetched"
        :onPullSuccessAsync="pullSupplier"
        :pageColor="themeColor"
        transparent
        :actualUsedHeight="0">
        <template v-slot="{ item }">
            <v-card :color="item.themeColour" class="pb-5">
                <v-card max-width="900" class="mx-auto">
                    <v-hover v-slot="{ hover }" open-delay="100" close-delay="100">
                        <v-app-bar :style="hover ? 'opacity: 0.85;' : 'opacity: 0.40;'" dark absolute rounded="0">
                            <v-slide-y-transition hide-on-leave>
                                <v-app-bar-nav-icon @click="$BlitzIt.navigation.navBack">
                                    <v-avatar size="36">
                                        <v-icon>mdi-arrow-left</v-icon>
                                    </v-avatar>
                                </v-app-bar-nav-icon>
                            </v-slide-y-transition>
                        </v-app-bar>
                    </v-hover>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-img
                                :src="companyBannerURL(item)"
                                class="mx-auto"
                                height="345"
                                width="900">
                                <template v-slot:placeholder>
                                    <v-img height="345" width="900" :src="companyBannerURL('default')" />
                                </template>
                            </v-img>
                        </v-col>
                        <v-col cols="12">
                            <v-avatar size="150" :style="avatarStyle">
                                <v-img :src="companyLogoURL(item)" style="border: 3px solid #ffffff">
                                    <template v-slot:placeholder>
                                        <v-icon size="150" color="primary">mdi-factory</v-icon>
                                    </template>
                                </v-img>
                            </v-avatar>
                        </v-col>
                        <v-col v-if="!$vuetify.breakpoint.mobile" cols="12">
                            <v-card :color="item.themeColour" :dark="item.themeColour != null" min-height="150" tile>
                                <v-card-title class="text-h5 font-weight-bold mb-0 pb-0" style="margin-left: 180px;">{{ item.companyName }}</v-card-title>
                                <v-card-text class="text-subtitle-1 mt-4">
                                    {{ item.publicDescription || 'Another supplier using the BlitzIt Web Platform for their small business.' }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col v-else cols="12">
                            <v-card 
                                :color="item.themeColour"
                                :dark="item.themeColour != null" 
                                style="min-height: calc(100vh - 393px); padding-top: 80px;"
                                tile>
                                <div class="text-center text-h5 font-weight-bold px-auto mx-auto">{{ item.companyName }}</div>
                                <v-card-text class="text-subtitle-1">
                                    {{ item.publicDescription || 'Another supplier using the BlitzIt Web Platform for their small business.' }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-divider />
                        <v-col v-if="isLengthyArray(categories)" cols="12" class="pa-4">
                            <v-subheader>Product Categories</v-subheader>

                            <v-row no-gutters>
                                <v-col v-for="(category, ind) in categories" :key="ind" cols="auto" class="ma-1 ma-sm-3">
                                    <v-card class="ma-1 pa-0 text-truncate" width="140" height="140">
                                        <v-hover v-slot="{ hover }">
                                            <v-img :src="productLogoURL(category.id)" class="my-auto mx-auto" height="140" width="150">
                                                <template v-slot:placeholder>
                                                    <div class="text-center">
                                                        <v-icon size="90" class="primary--text mt-9">mdi-shape-outline</v-icon>
                                                    </div>
                                                </template>
                                                <template v-slot:default>
                                                    <v-toolbar bottom dark :style="hover ? 'opacity: 0.85;' : 'opacity: 0.65;'">
                                                        <div class="text-subtitle-2">{{ category.categoryName }}</div>
                                                    </v-toolbar>
                                                </template>
                                            </v-img>
                                        </v-hover>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        
                        <v-col v-if="isLengthyArray(products)" cols="12" class="pa-4">
                            <v-subheader>Products</v-subheader>

                            <v-row no-gutters>
                                <v-col v-for="(product, ind) in products" :key="ind" cols="12" sm="5" class="ma-1 ma-sm-3">
                                    <v-card class="ma-1 pa-2">
                                        <div width="100%" class="text-center">
                                            <BT-Avatar v-model="product.id" size="200" avatarType="product" />
                                        </div>
                                        <v-card-title>{{ product.productName }}</v-card-title>
                                        <v-card-subtitle>{{ product.abbreviation }}</v-card-subtitle>
                                        <v-card-text>{{ product.publicDescription }}</v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card>
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Public-Supplier-Blade',
    data: () => {
        return {
            loadingMsg: null,
            categories: [],
            currentItem: null,
            currentRequest: null,
            agreement: null,
            products: [],
        }
    },
    computed: {
        canRequest() {
            return this.$BlitzIt.auth.session.isLoggedIn &&
                this.$BlitzIt.auth.session.data?.companyAccountID != this.currentItem?.id &&
                this.currentItem?.isOpenToAgreementRequests
        },
        avatarStyle() {
            return this.$vuetify.breakpoint.mobile ? 'margin-top: -75px; position: absolute; z-index: 1; margin-left: calc(50% - 75px)' : 'margin-top: -100px; margin-left: 30px; position: absolute; z-index: 1;';
        },
        isMe() {
            return this.$BlitzIt.auth.session.isLoggedIn &&
                this.$BlitzIt.auth.session.data?.companyAccountID == this.currentItem?.id
        },
        themeColor() {
            return this.currentItem?.themeColour ?? null;
        }
    },
    destroyed() {
        this.$BlitzIt.style.undoTemporaryColor(this.$vuetify);
    },
    methods: {
        email(item) {
            window.open('mailto:' + item.primaryEmail);
        },
        phone(item) {
            window.open('tel:' + item.phoneNumber);
        },
        website(item) {
            window.open(item.website, '_blank');
        },
        pullSupplier(item) {
            console.log('pul');
            this.currentItem = item;
            this.$BlitzIt.style.setTemporaryColor(this.$vuetify, item.themeColour);
            return item;
        },
        async itemFetched(item) {
            this.products = await this.$BlitzIt.store.getAll('public-products', { companyID: item.id });
            this.categories = await this.$BlitzIt.store.getAll('public-product-categories', { companyID: item.id });

            // res = await this.$BlitzIt.store.get('suppliers', item.id, { includeDetails: false, supplierID: item.id }, false);
            // console.log(res);
            // this.agreement = res;

            // if (this.agreement == null) {
            //     this.currentRequest = await this.$BlitzIt.store.getAll('supplier-requests', { sellerID: item.id });
            // }
        },
        request() {
            this.loading = true;            
            this.$BlitzIt.store.post(
                'supplier-requests',
                { sellerID: this.currentItem.id }
            )
            .then(res => {
                this.loading = false;
                this.currentRequest = res;
            })
            .catch(err => {
                console.log(err);
                this.loading = false;
            })
        },
        cancelRequest() {
            this.loading = true;            
            this.$BlitzIt.store.delete('supplier-requests', this.currentRequest.id)
                .then(() => {
                    this.currentRequest = null;                    
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                })
        }
    }
}
</script>